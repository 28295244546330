<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span></div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled"></budget-header>
            </div>
            <div class="right-content">
                <div class="actions-tab">
                    <budget-attach-file/>
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadRep">Форма {{ form.code }}</b-dropdown-item>
                        <b-dropdown-item @click="downloadBatchReports" :disabled="$store.state.isDownloadButtonEnabled">
                            {{$store.state.isDownloadButtonEnabled ? $t("modules.budget.budget_request.uploadIsProgress") : $t("modules.budget.budget_request.batchUploadButton") }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="filter-actions">
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering"></td>
                </template>
                <template #cell(name_ru)="data">
                    <div class="scroll-container">{{data.item.name_ru}}</div>
                </template>
                <template #cell(avg_annual)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.avg_annual"
                                  @change="v => data.item.avg_annual = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'avg_annual', data.item.avg_annual, 1)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(amount_months)="data">
                    <div v-if="variantAttribute" :class="{ 'error': data.item.amount_months > 12.0 }">
                        <b-form-input class="text-right"
                                      :value="data.item.amount_months"
                                      @change="v => data.item.amount_months = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixed(data.item, 'amount_months', data.item.amount_months, 1)">
                        </b-form-input>
                        <template v-if="data.item.amount_months > 12.0">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Ограничение по вводу: только числа от "1.0" до "12.0"</p>
                            </div>
                        </template>
                    </div>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(salary)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.salary"
                                  @change="v => data.item.salary = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'salary', data.item.salary, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(surcharge)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.surcharge"
                                  @change="v => data.item.surcharge = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'surcharge', data.item.surcharge, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">
                        {{ $n(data.item.total) }}
                    </div>
                </template>
                <template #cell(more)="data">
                    <b-button v-if="variantAttribute" @click="deleteItem(data.item, data.index)">
                        <i class="icon icon-close"></i>
                    </b-button>
                </template>

                <template #bottom-row="data">
                    <td></td>
                    <td class="text-left" colspan="5">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute" :load="load" @getFiles="getFiles"></files-updown>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import BudgetAttachFile from "./components/budget-attach-file";

export default {
    name: 'Form01-324',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, BudgetAttachFile, BreadcrumbsFilter },
    data() {
        return {
            form: {
                code: '01-324',
                name_kk: '',
                name_ru: 'Расчет расходов на выплату стипендии студентам, интернам, магистрантам, докторантам, слушателям, курсантам военно-учебных специальных учебных заведений и кадетов'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name_ru',
                    label: 'Перечень стипендиатов'
                },
                {
                    key: 'avg_annual',
                    label: 'Среднегодовая численность, единиц'
                },
                {
                    key: 'amount_months',
                    label: 'Количество месяцев'
                },
                {
                    key: 'salary',
                    label: 'Сумма должностного оклада (стипендии) в месяц (базовый должностной оклад х коэффициент х графа 2), тенге'
                },
                {
                    key: 'surcharge',
                    label: 'Доплата за специальное звание в месяц (базовый должностной оклад х коэффициент), тенге'
                },
                {
                    key: 'total',
                    label: 'Должностной оклад (стипендия) в год ((графа 4+графа 5) х графа 3)/1000, тысяч тенге'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: null,
            files: null,
            load: false,
            openDisabled: false,
            mrp: 0,
            dictMilitaryScholars: [],
            isLoad: false
        };
    },
    async mounted() {
        // await this.loadDict();
        await this.loadMilitaryScholars();
    },
    methods: {
        async changeHeader(data) {
            try {
                this.openDisabled = true;
                const curHeader = data;

                if ((curHeader !== null)
                    && (curHeader.dataType !== null)
                    && (curHeader.gr !== null)
                    && (curHeader.gu !== null)
                    && (curHeader.prg !== null)) {
                    this.header = {
                        form: this.form.code,
                        year: curHeader.yearProp,
                        cur_year: curHeader.year,
                        data_type: curHeader.dataType.code,
                        gu: curHeader.gu.code,
                        id_region: curHeader.gu.id_region,
                        gr: curHeader.gr.gr,
                        abp: curHeader.abp.abp,
                        prg: curHeader.prg.prg,
                        ppr: (curHeader.pgr === null ? null : curHeader.pgr.ppr),
                        spf: curHeader.spf.spf,
                        region_code: curHeader.region,
                        variant: curHeader.budgetVersion.variant_uuid,
                        user_name: this.$store.state.user.sub
                    };
                    if (curHeader.gu.budget_type === '02') {
                        this.header.id_region = curHeader.region;
                    }
                    this.dataTypeFilter = curHeader.dataType.name;
                    // если атрибут поля attribute в budget_variants имеет значение true - редактирование формы возможно,
                    // если значение false/null - редактирование запрещено
                    this.variantAttribute = curHeader.budgetVersion.attribute;
                    this.variantName = curHeader.budgetVersion.name_ru;
                    await this.loadDatas();
                } else {
                    this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров');
            } finally {
                this.openDisabled = false;
            }
        }, // обновление параметров

        deleteItem(row) {
            this.$bvModal.msgBoxConfirm(
                'Очистить данные записи?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        row.avg_annual = 0;
                        row.amount_months = 0;
                        row.salary = 0;
                        row.surcharge = 0;
                        this.prepareForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления записи', error.toString());
                });
        }, // очистить значения строки

        downloadRep() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма 01-324.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        openFilterByRef(refName) {
            this.$refs.budgetHeader.openFilterByRef(refName);
        },

        downloadBatchReports() {
            this.$store.commit('setIsDownloadButtonEnabled');
            this.makeToast('info', 'Внимание', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
            Ax(
                {
                    url: '/api-py/budg_batch_reports/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    if (data && data.size > 0) {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Пакетная выгрузка.xls');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.$store.commit('setIsDownloadButtonEnabled');
                    } else {
                        throw new Error('Error');
                    }
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadBatchReports()', error.toString());
                    this.$store.commit('setIsDownloadButtonEnabled');
                }
            );
        },

        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой

        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            that.load = true;
            that.budgetForm.splice(0);
            for (const item of that.dictMilitaryScholars) {
                const dataItem = {
                    id: 0,
                    name_ru: item.name_ru,
                    code: item.code,
                    avg_annual: 0,
                    amount_months: 0,
                    salary: 0,
                    surcharge: 0
                };
                Object.defineProperty(dataItem, 'total', {
                    get: function () {
                        const sum = (parseFloat(dataItem.salary) + parseFloat(dataItem.surcharge)) * parseFloat(dataItem.amount_months) / 1000;
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                that.budgetForm.push(dataItem);
            }

            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form/' + JSON.stringify(that.header));
                values = await response.json();
                if (values.length === 0) {
                    return;
                }
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadDatas()', error.toString());
            }

            await values.forEach(val => {
                const itemExist = that.budgetForm.filter(function (item) {
                    if (item.code === val.scholar_type) { return item; }
                });

                if (itemExist.length > 0) {
                    itemExist[0].id = val.id;
                    itemExist[0].avg_annual = val.avg_annual;
                    itemExist[0].amount_months = val.amount_months;
                    itemExist[0].salary = val.salary;
                    itemExist[0].surcharge = val.surcharge;
                }
            });
            that.load = false;
        },

        async loadMilitaryScholars() {
            try {
                this.dictMilitaryScholars = [];
                const response = await fetch('/api-py/dictionary/dict_military_scholars/');
                const msList = await response.json();
                msList.forEach(ms => {
                    this.dictMilitaryScholars.push({
                        code: ms.code,
                        name_ru: ms.name_ru
                    });
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadMilitaryScholars()', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (parseFloat(row.amount_months) < 12.1) {
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'scholar_type', row.code);
                    this.$set(item, 'avg_annual', parseFloat(row.avg_annual));
                    this.$set(item, 'amount_months', parseFloat(row.amount_months));
                    this.$set(item, 'salary', parseFloat(row.salary));
                    this.$set(item, 'surcharge', parseFloat(row.surcharge));
                    values.push(item);
                } else {
                    error = true;
                }
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save(values, error);
            } else {
                this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
            }
        },

        async save(values, error) {
            this.isLoad = true;
            try {
                const response = await fetch('/api-py/save-brform' + this.form.code + '/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    if (error) {
                        this.makeToast('danger', 'Сообщение', 'Ошибочно введенные данные не были сохранены');
                    } else {
                        this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    }
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const response = await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        }
    }
};
</script>
<style scoped>
.scroll-container {
    display: block;
    width: 350px;
    max-height: 200px;
    overflow-y: auto;
    scroll-behavior: smooth;
}
</style>